import { useStoreConfig } from 'common/providers/config';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { BannersList as BannersListComp, BannersListProps } from 'domains/product/components/banners-list';
import { GetAllBannersEndpoint } from 'endpoints/banner/get-all-banners';

type Props = Omit<BannersListProps, 'items' | 'loading'>;

const BannersList = (props: Props) => {
  const { saleType } = useSaleType();
  const { platformOs } = usePlatformOs();
  const { data, isLoading } = GetAllBannersEndpoint.useRequest({ saleType, platformOs });
  const { usageDisclaimer } = useStoreConfig();
  return (
    <BannersListComp
      {...props}
      items={data?.banners ?? []}
      loading={isLoading}
      disclaimer={usageDisclaimer}
    />
  );
};

export { BannersList };
export default BannersList;
