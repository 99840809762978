import reducePreloads from '@swe/shared/network/helpers/reduce-preloads';

import { ApplicationPage } from 'app/types';
import { getSeo } from 'common/use-cases/use-seo';
import { Home } from 'domains/shop/containers/home';
import { GetCarouselBannersEndpoint } from 'endpoints/banner/get-carousel-banners';
import GetCarouselList from 'endpoints/product/get-carousel-list';
import GetCategoryListEndpoint from 'endpoints/product/get-category-list';
import GetPromotionListEndpoint from 'endpoints/promotion/get-promotion-list';
import GetShopInfoEndpoint from 'endpoints/shop/get-shop-info';
import { ShopInfo } from 'entities/shop/info';

const HomePage: ApplicationPage = () => {
  return <Home />;
};

HomePage.getMeta = ({ queryClient, intl, storeConfig }) => {
  const storeInfo = queryClient.getQueryData(GetShopInfoEndpoint.key())! as ShopInfo;
  const { builder, payload } = getSeo(storeConfig.dealerName, storeInfo);
  const { name, cityName, saleTypesFormatted, fulfillmentTypesFormatted, saleTypes } = payload;

  builder.title.add(
    intl.t('pages.home.documentTitle', payload, `Welcome to {storeName} - buy cannabis online | {cityName}`),
  );

  builder.description.add([
    'Want to buy Cannabis online?',
    `Welcome to ${name}`,
    cityName,
    saleTypesFormatted,
    ...fulfillmentTypesFormatted,
  ]);

  builder.keywords.add([
    name,
    cityName,
    'dispensary',
    'cannabis',
    'Cannabis store',
    'Cannabis online',
    `Cannabis store ${cityName}`,
    `buy cannabis ${cityName}`,
    'best cannabis',
    'best dispensaries',
    `dispensaries in ${cityName}`,
    'cannabis dispensary',
    'marijuana dispensary',
    'weed dispensary',
    `cannabis dispensary in ${cityName}`,
    saleTypes.every((type) => type === 'Medical') ? 'medical dispensary' : 'recreational dispensary',
  ]);

  return builder.toProps();
};

HomePage.preload = ({ headers, queryClient }, { saleType, platformOs, homePageDealsCarouselIsEnabled }) => {
  return reducePreloads([
    ...(homePageDealsCarouselIsEnabled
      ? [GetPromotionListEndpoint.preload({ saleType, platformOs }, { headers }, queryClient)]
      : []),
    GetCarouselBannersEndpoint.preload({ saleType, platformOs }, { headers }, queryClient),
    GetCategoryListEndpoint.preload({ saleType }, { headers }, queryClient),
    GetCarouselList.preload({ saleType, platformOs }, { headers }, queryClient),
  ]);
};

export default HomePage;
